import { TypographyLabel } from '../typography'
import { type ReactNode } from 'react'
import { PlaceholderIcon } from '../icons'
import clsx from 'clsx'

const Tag = ({
	icon = <PlaceholderIcon />,
	children,
	variant = 'outline',
	size = 'medium',
	disabled = false,
	showIcon = true,
	...props
}: {
	icon?: ReactNode
	children: ReactNode
	variant?: 'outline' | 'fill'
	size?: 'small' | 'medium' | 'large'
	disabled?: boolean
	showIcon?: boolean
	onClick?: () => void
}) => {
	const calculateTypographySize = (size: string) => {
		if (size === 'small') {
			return 'xSmall'
		} else if (size === 'medium') {
			return 'small'
		} else if (size === 'large') {
			return 'medium'
		} else {
			return 'small'
		}
	}
	return (
		<div
			className={clsx(
				// Shared Styles
				'flex items-center gap-x-1 rounded-full',
				// Size Styles
				size === 'small' && 'px-2.5 py-1',
				size === 'medium' && 'px-2.5 py-1',
				size === 'large' && 'px-4 py-2',
				// Disabled/Enabled Shared Styles
				disabled === Boolean(true)
					? // Disabled Styles
						'cursor-default text-textSecondaryLight/50 dark:text-textSecondaryDark/50'
					: // Enabled Styles
						'cursor-pointer text-textSecondaryLight hover:text-textPrimaryLight active:bg-textPrimaryLight active:text-textPrimaryDark dark:text-textSecondaryDark dark:hover:text-textPrimaryDark dark:active:bg-textPrimaryDark dark:active:text-textSecondaryLight',
				// Outline Styles
				variant === 'outline' &&
					'border border-textInactiveLight hover:border-textSecondaryLight dark:border-textInactiveDark',
				// Disabled/Enabled Outline Styles
				variant === 'outline' &&
					disabled === Boolean(true) &&
					'border-textSecondaryLight/50 dark:border-textSecondaryDark/50',
				// Fill Styles
				variant === 'fill' && 'bg-uiSecondaryLight dark:bg-uiSecondaryDark',
				// Disabled/Enabled Fill Styles
				variant === 'fill' && 'bg-uiSecondaryLight dark:bg-uiSecondaryDark',
			)}
			{...props}
		>
			{showIcon && icon && (
				<div className="flex h-6 w-6 items-center justify-center">{icon}</div>
			)}
			<TypographyLabel
				size={calculateTypographySize(size)}
				isBold={true}
				additionalClasses={clsx('flex items-center h-full whitespace-nowrap')}
			>
				{children}
			</TypographyLabel>
		</div>
	)
}

export default Tag
